import * as i0 from '@angular/core';
import { EventEmitter, Injectable, Optional, Directive, Input, NgModule, SkipSelf } from '@angular/core';
import { BehaviorSubject, AsyncSubject } from 'rxjs';
import { skip, take } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
class GoogleSignInConfig {
  constructor() {
    this.autoLogin = true;
    this.enableLocalStorageToken = false;
  }
}
class GoogleAuthenticationService {
  static {
    this.PROVIDER_ID = 'GOOGLE';
  }
  get initState() {
    return this._initState.asObservable();
  }
  get userData() {
    return this._userData.asObservable();
  }
  get token() {
    const token = sessionStorage.getItem('ses') || localStorage.getItem('ses') || '';
    return token;
  }
  set token(token) {
    if (!this.config?.enableLocalStorageToken) {
      sessionStorage.setItem('ses', token);
    } else {
      localStorage.setItem('ses', token);
    }
  }
  constructor(config) {
    this.config = config;
    this.authUser = new EventEmitter();
    this._userData = new BehaviorSubject(null);
    this._initState = new AsyncSubject();
    // emit authUser events but skip initial value from behaviorSubject
    this._userData.pipe(skip(1)).subscribe(this.authUser);
    if (config instanceof Promise) {
      config.then(config => {
        this.initialize(config);
      });
    } else {
      this.initialize(config);
    }
  }
  initialize(config) {
    this.load(config.apiKey).then(() => {
      this.checkIfLoggedInAndValid(this.token);
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      this._initState.next(true);
      this._initState.complete();
    });
  }
  load(apiKey) {
    return new Promise((resolve, reject) => {
      try {
        this.loadScript(GoogleAuthenticationService.PROVIDER_ID, 'https://accounts.google.com/gsi/client', () => {
          google.accounts.id.initialize({
            client_id: apiKey,
            auto_select: true,
            callback: response => {
              const socialUser = this.buildUserData(response.credential);
              this.token = response.credential;
              this._userData.next(socialUser);
              this.controlTimeExpirationToken(response.credential);
            },
            prompt_parent_id: undefined,
            itp_support: true
          });
          if (!this.isLoggedIn()) {
            this.promptLogin();
          }
          if (this.token && !this.isTokenExpired(this.token)) {
            this.controlTimeExpirationToken(this.token);
          }
          resolve();
        });
      } catch (err) {
        reject(err);
      }
    });
  }
  checkIfLoggedInAndValid(jwt) {
    if (jwt && !this.isTokenExpired(jwt)) {
      const socialUser = this.buildUserData(jwt);
      this._userData.next(socialUser);
    }
  }
  loadScript(id, src, onload, parentElement = null) {
    // get document if platform is only browser
    if (typeof document !== 'undefined' && !document.getElementById(id)) {
      let signInJS = document.createElement('script');
      signInJS.async = true;
      signInJS.src = src;
      signInJS.onload = onload;
      if (!parentElement) {
        parentElement = document.head;
      }
      parentElement.appendChild(signInJS);
    }
  }
  buildUserData(idToken) {
    const payload = this.decodeJwt(idToken);
    const user = {
      idToken: idToken,
      id: payload.sub,
      name: payload.name,
      email: payload.email,
      photoUrl: payload.picture,
      firstName: payload['given_name'],
      lastName: payload['family_name']
    };
    return user;
  }
  decodeJwt(idToken) {
    const base64Url = idToken.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(window.atob(base64).split("").map(function (c) {
      return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(""));
    return JSON.parse(jsonPayload);
  }
  isTokenExpired(token) {
    if (token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(atob(base64).split("").map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(""));
      const {
        exp
      } = JSON.parse(jsonPayload);
      const expired = Date.now() >= exp * 1000;
      return expired;
    }
    return true;
  }
  isLoggedIn() {
    return !this.isTokenExpired(this.token);
  }
  /**
   *
   * @param el Element HTML to render button
   * @param options options to configure buttons same as cx-google-sign-in-button directive
   */
  renderButtonLogin(el, options = {}) {
    this._initState.subscribe({
      next: () => {
        google.accounts.id.renderButton(el.nativeElement, options);
      }
    });
  }
  /**
   * Deletes sessions from Google SDK and sessionStorage and localStorage
   */
  async logout() {
    google.accounts.id.disableAutoSelect();
    sessionStorage.removeItem('ses');
    localStorage.removeItem('ses');
    this._userData.next(null);
  }
  controlTimeExpirationToken(jwt) {
    const payload = this.decodeJwt(jwt);
    const expirationTimeToken = parseInt(payload.exp) * 1000;
    const currentTime = Date.now();
    const controlTimeExpirationToken = 2 * 60 * 1000; // 2 minutos
    if (currentTime >= expirationTimeToken) {
      // El token ha expirado
      this.promptLogin();
    } else if (expirationTimeToken - currentTime <= controlTimeExpirationToken) {
      // El token expirará en menos de 2 minutos, ejecutar la función ahora
      this.promptLogin();
    } else {
      // El token todavía es válido, establecer un temporizador para la función de expiración
      const timeResult = expirationTimeToken - currentTime - controlTimeExpirationToken;
      setTimeout(this.promptLogin.bind(this), timeResult);
    }
  }
  promptLogin() {
    if (this.config?.autoLogin && this.token) {
      google.accounts.id.prompt(notification => {
        if (notification.isNotDisplayed() || notification.isSkippedMoment() || notification.isDismissedMoment() && notification.getDismissedReason() != 'credential_returned') {
          // no sign-in happened, display the button
          google.accounts.id.renderButton(document.getElementById('parent'), {});
        }
      });
    }
  }
  static {
    this.ɵfac = function GoogleAuthenticationService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || GoogleAuthenticationService)(i0.ɵɵinject(GoogleSignInConfig, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: GoogleAuthenticationService,
      factory: GoogleAuthenticationService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GoogleAuthenticationService, [{
    type: Injectable
  }], () => [{
    type: GoogleSignInConfig,
    decorators: [{
      type: Optional
    }]
  }], null);
})();
class GoogleSignInButtonDirective {
  constructor(el, googleAuthenticationService) {
    this.el = el;
    this.googleAuthenticationService = googleAuthenticationService;
    this.type = 'icon';
    this.size = 'medium';
    this.text = 'signin_with';
    this.shape = 'rectangular';
    this.theme = 'outline';
    this.logo_alignment = 'left';
    this.width = '';
    this.locale = '';
    this.googleAuthenticationService.initState.pipe(take(1)).subscribe(() => {
      Promise.resolve(this.width).then(value => {
        if (value > '400' || value < '200' && value != '') {
          Promise.reject('Please note .. max-width 400 , min-width 200 ' + '(https://developers.google.com/identity/gsi/web/tools/configurator)');
        } else {
          google.accounts.id.renderButton(el.nativeElement, {
            type: this.type,
            size: this.size,
            text: this.text,
            width: this.width,
            shape: this.shape,
            theme: this.theme,
            logo_alignment: this.logo_alignment,
            locale: this.locale
          });
        }
      });
    });
  }
  static {
    this.ɵfac = function GoogleSignInButtonDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || GoogleSignInButtonDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(GoogleAuthenticationService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: GoogleSignInButtonDirective,
      selectors: [["cx-google-sign-in-button"]],
      inputs: {
        type: "type",
        size: "size",
        text: "text",
        shape: "shape",
        theme: "theme",
        logo_alignment: "logo_alignment",
        width: "width",
        locale: "locale"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GoogleSignInButtonDirective, [{
    type: Directive,
    args: [{
      selector: 'cx-google-sign-in-button',
      standalone: true
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: GoogleAuthenticationService
  }], {
    type: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    text: [{
      type: Input
    }],
    shape: [{
      type: Input
    }],
    theme: [{
      type: Input
    }],
    logo_alignment: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    locale: [{
      type: Input
    }]
  });
})();

// @dynamic
class GoogleSignInModule {
  constructor(parentModule) {
    // if (parentModule) {
    //   throw new Error(
    //     'GoogleSignInModule is already loaded. Import it in the AppModule only');
    // }
  }
  static initialize(config) {
    return {
      ngModule: GoogleSignInModule,
      providers: [GoogleAuthenticationService, {
        provide: GoogleSignInConfig,
        useValue: config
      }]
    };
  }
  static {
    this.ɵfac = function GoogleSignInModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || GoogleSignInModule)(i0.ɵɵinject(GoogleSignInModule, 12));
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: GoogleSignInModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [GoogleAuthenticationService],
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GoogleSignInModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, GoogleSignInButtonDirective],
      exports: [GoogleSignInButtonDirective],
      providers: [GoogleAuthenticationService]
    }]
  }], () => [{
    type: GoogleSignInModule,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { GoogleAuthenticationService, GoogleSignInButtonDirective, GoogleSignInModule };
